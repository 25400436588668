import React, { FC } from 'react';
import SEOBase from '@/newcar/ui/common/organism/SEO';

const TITLE_POSTFIX = '｜おトクにマイカー 定額カルモくん';
const DESCRIPTION_POSTFIX = '【ネット割最大49,500円】頭金・ボーナス払い0円で新車に格安で乗れる。毎月定額、税金コミコミで安心です。メンテナンスも月額定額にできるプランや、契約満了後に車がもらえるオプションもご用意。';

export type ContactSEO = {
  pageTitle: string
  pageDescription: string
};

const SEO: FC<ContactSEO> = ({ pageTitle, pageDescription }) => {
  const title = pageTitle + TITLE_POSTFIX;
  const description = pageDescription + DESCRIPTION_POSTFIX;

  return (
    <SEOBase
      title={title}
      description={description}
      ogTitle={title}
      ogDescription={description}
      ogUrl={`${process.env.GATSBY_SITE_URL}/contact/`}
      twitterTitle={title}
      twitterDescription={description}
    />
  );
};

export default SEO;
