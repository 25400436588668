import React, { FC } from 'react';
import { Link } from 'gatsby';
import Layout from '@/common/ui/template/Layout';
import Header from '@/newcar/ui/common/organism/Header';
import Breadcrumb from '@/newcar/ui/common/molecule/Breadcrumb';
import ContactCompleted from '@/newcar/ui/contact/organism/ContactCompleted';
import ScrollToTop from '@/common/ui/organism/ScrollToTop';
import Footer from '@/newcar/ui/common/organism/Footer';
import SEO from '@/newcar/ui/contact/organism/SEO';
import NEWCAR_DEFINE from '@/newcar/util/define';
import * as styles from '@/pages/contact/index.module.styl';

const Page: FC = () => (
  <Layout>
    <Header>
      <Breadcrumb
        items={[
          { to: NEWCAR_DEFINE.PATH.TOP, label: '新車TOP' },
          { label: 'お問合せ' },
        ]}
      />
    </Header>

    <main className="columns has-background-white-ter">
      <div className={`column is-full-touch is-half-desktop ${styles.contentContainer}`}>
        <section className={styles.contentSpacing}>
          <h1 className="is-size-3">お問合せ</h1>
          <ContactCompleted />
        </section>

        <section>
          <SEO pageTitle="お問合せ完了" pageDescription="お問合せを完了しました。" />
          <div className={`box ${styles.boxStyle} ${styles.marginBottom}`}>
            <Link to={NEWCAR_DEFINE.PATH.TOP}><div className={`is-size-4 ${styles.toTop}`}>定額カルモくんトップ</div></Link>
          </div>
        </section>
      </div>
    </main>

    <Footer />
    <ScrollToTop />
  </Layout>
);

export default Page;
