import React, { FC } from 'react';
import * as styles from '@/pages/contact/index.module.styl';

const ContactCompleted: FC = () => (
  <>
    <p className={`is-size-5 ${styles.contentText}`}>
      お問合せいただきありがとうございます。
      <br />
      返信までしばらくお待ち下さい。
    </p>
  </>
);

export default ContactCompleted;
